var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view__container"},[_c('div',{staticClass:"content__top"},[_c('div',[_c('Breadcrumbs',{attrs:{"views":[{ label: 'Estructura Salarial Externa', to: '/estructura-salarial-externa' }],"currentView":{ label: 'Empleados' }}}),_c('h2',[_vm._v("Empleados: "+_vm._s(_vm.position.name))])],1),_c('div',{staticClass:"content__buttons"},[_c('Menu',{attrs:{"direction":"left"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('Button',{attrs:{"type":"button","variant":"secondary","size":"xsmall"}},[_c('unicon',{staticClass:"ellipsis",attrs:{"name":"ellipsis-h","fill":"currentColor","height":"16px","width":"16px"}})],1)]},proxy:true},{key:"options",fn:function(){return [_c('menu-item',{on:{"click":_vm.downloadExcel}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"file-download","fill":"var(--font-color-700)"}}),_vm._v(" Descargar Excel ")],1)]},proxy:true}])})],1)]),_c('div',{staticClass:"filter__container"},[_c('Menu',{attrs:{"direction":"below","closeOnItemClick":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('filter-item',{attrs:{"filter":_vm.incomeFilter}})]},proxy:true},{key:"options",fn:function(){return _vm._l(([].concat( _vm.incomeFilter.options )),function(option){return _c('menu-item',{key:option.id,on:{"click":function($event){option.active = !option.active;
            _vm.filterEmployees();}}},[_vm._v(" "+_vm._s(option.name)+" "),(option.active)?_c('unicon',{attrs:{"width":"16px","height":"16px","name":"check","fill":""}}):_vm._e()],1)})},proxy:true}])}),_c('Filters',{ref:"filters",attrs:{"filters":_vm.categories,"filtersOptions":_vm.options},on:{"filter":function (activeFilters) {
          _vm.filters = activeFilters;
          _vm.filterEmployees(activeFilters);
        }}})],1),_c('div',{staticClass:"content"},[_c('table',{ref:"table"},[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("#")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Nombre")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Nivel laboral")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Fecha de ingreso")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Género")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Ingresos")]),_vm._l((_vm.markets.filter(function (ref) {
                var isHidden = ref.isHidden;

                return !isHidden;
})),function(market){return _c('th',{key:market.id,staticClass:"center",style:({ 'background-color': market.color, color: 'white' }),attrs:{"colspan":Object.values(market.positions[Object.keys(market.positions)[0]]).length}},[_vm._v(" "+_vm._s(market.name)+" ")])})],2),_c('tr',{staticClass:"second__header"},[_vm._l((_vm.markets.filter(function (ref) {
                var isHidden = ref.isHidden;

                return !isHidden;
})),function(market){return [_c('th',{key:market.id + 'header-min',style:({ 'background-color': market.color, color: 'white' })},[_vm._v(" Mínimo ")]),_c('th',{key:market.id + 'header-mean',style:({ 'background-color': market.color, color: 'white' })},[_vm._v(" Media ")]),_c('th',{key:market.id + 'header-median',style:({ 'background-color': market.color, color: 'white' })},[_vm._v(" Mediana ")]),_c('th',{key:market.id + 'header-max',style:({ 'background-color': market.color, color: 'white' })},[_vm._v(" Superior ")])]})],2),_vm._l((_vm.filteredEmployees),function(employee,index){return _c('tr',{key:employee.id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(employee.name))]),_c('td',[_vm._v(_vm._s(_vm.options.find(function (ref) {
                var id = ref.id;

                return id === employee.nivellaboral;
        }).name))]),_c('td',[_vm._v(_vm._s(_vm.timeStampToDMY(employee.entryDate)))]),_c('td',[_vm._v(_vm._s(employee.gender))]),_c('td',[_c('span',{staticClass:"median"},[_vm._v(_vm._s(employee.salary.toFixed(2)))])]),_vm._l((_vm.markets.filter(function (ref) {
                var isHidden = ref.isHidden;

                return !isHidden;
        })),function(market){return _vm._l((['min', 'mean', 'median', 'max']),function(val){return _c('td',{key:market.id + val},[_c('span',{staticClass:"market__value",class:{
                selected:
                  market.positions[employee.puesto] &&
                  Object.values(market.positions[employee.puesto]).reduce(
                    function (closest, currentNumber) { return Math.abs(currentNumber - employee.salary) <
                      Math.abs(closest - employee.salary)
                        ? currentNumber
                        : closest; },
                    Infinity
                  ) === market.positions[employee.puesto][val],
              },style:({
                '--market-color': market.color,
              })},[_vm._v(" "+_vm._s(market.positions[employee.puesto] ? market.positions[employee.puesto][val] : '-')+" ")])])})})],2)})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }